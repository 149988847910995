import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import OurOfferings from "../sections/landing/offerings";

function IndexPage() {
  return (
    <Layout sticky>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Offerings"
      />
      <OurOfferings/>

    </Layout>
  );
}

export default IndexPage;
